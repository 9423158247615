import React, { useState, useEffect, useReducer, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { MenuItem, FormControl, InputLabel, Select } from "@material-ui/core";

import ConfirmationModal from "../../components/ConfirmationModal";

import toastError from "../../errors/toastError";

import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";

import { i18n } from "../../translate/i18n";

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: '2rem'
  },
  inputContainer: {
    display: 'flex',
    width: '100%',
    marginBottom: '1rem'
  },
  input: {
    flexGrow: 1,
    marginRight: '1rem'
  },
  listContainer: {
    width: '100%',
    height: '100%',
    marginTop: '1rem',
    backgroundColor: '#f5f5f5',
    borderRadius: '5px',
  },
  list: {
    marginBottom: '5px'
  }
});

const reducer = (state, action) => {
  if (action.type === "LOAD_TASKS") {
    const tasks = action.payload;

    return [...tasks];
  }

  if (action.type === "UPDATE_TASKS") {
    const task = action.payload;
    const taskIndex = state.findIndex((u) => u.id === task.id);

    if (taskIndex !== -1) {
      state[taskIndex] = task;
      return [...state];
    } else {
      return [task, ...state];
    }
  }

  if (action.type === "DELETE_TASK") {
    const taskId = action.payload;

    const taskIndex = state.findIndex((u) => u.id === taskId);
    if (taskIndex !== -1) {
      state.splice(taskIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const ToDoList = () => {
  const classes = useStyles();

  const [deletingTask, setDeletingTask] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [tasks, dispatch] = useReducer(reducer, []);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [task, setTask] = useState('');
  const [editIndex, setEditIndex] = useState(-1);

  const { user } = useContext(AuthContext);

  const isAdmin = React.useMemo(_ => user.super || user.profile === 'admin', [user.super, user.profile]);

  const fetchUsers = () => {
    const controller = new AbortController();

    if(!isAdmin) {
      return controller;
    }
  
    api.get("/users", { signal: controller.signal })
      .then(response => {
        setUsers(response.data.users || []);
      }).catch (error => {
        console.error(error);
      });

    return controller;
  };


  useEffect(() => {
    setSelectedUsers([user.id]);
    const controller = fetchUsers();
    return () => {
      controller.abort();
    }
  }, [])

  useEffect(() => {

    const controller = new AbortController();
  
    const delayDebounceFn = setTimeout(() => {
      api.get("/tasks/", {
        params: { searchParam: "", pageNumber: 1, users: JSON.stringify(selectedUsers) },
        signal: controller.signal
      }).then(({ data }) => {
        dispatch({ type: "LOAD_TASKS", payload: data.tasks });
      }).catch(err => {
        toastError(err);
      });
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
      controller.abort();
    };
  }, [selectedUsers]);

  const handleTaskChange = (event) => {
    setTask(event.target.value);
  };

  const handleAddTask = async () => {
    if (!task.trim()) {
      // Impede que o usuário crie uma tarefa sem texto
      return;
    }


    if(editIndex >= 0){
      const id = tasks[editIndex].id;
      const { data } = await api.put(`/tasks/${id}`, {
        name: task
      });
      dispatch({ type: "UPDATE_TASKS", payload: data });
    } else {
      const { data } = await api.post("/tasks/", {
        name: task
      });
      dispatch({ type: "UPDATE_TASKS", payload: data });
    }


    setTask('');
    setEditIndex(-1);
  };

  const handleEditTask = (index) => {
    setTask(tasks[index].name);
    setEditIndex(index);
  };

  const handleDeleteTask = async (id) => {
    await api.delete(`/tasks/${id}`);
    dispatch({ type: "DELETE_TASK", payload: id });
  };

  const handleChangeUser = (selected) => {
    //const params = {users: JSON.stringify(selected)};
    setSelectedUsers(selected);
  }

  return (
    <div className={classes.root}>
      <ConfirmationModal
        title={
          deletingTask &&
          `${i18n.t("tasks.confirmationModal.deleteTitle")} ${
            deletingTask.name
          }?`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeleteTask(deletingTask.id)}
      >
        {i18n.t("tasks.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      {isAdmin && <FormControl margin="dense" variant="outlined" style={{minWidth: '10vw'}}>
          <InputLabel>{i18n.t("kanban.user")}</InputLabel>
          <Select
              id="type-select"
              labelWidth={60}
              name="user"
              onChange={(event) => handleChangeUser(event.target.value)}
              value={selectedUsers}
              multiple={true}
          >
              {users.map(user => 
                <MenuItem key={user.id} value={user.id}>
                  {user.name}
              </MenuItem>)}
          </Select>
        </FormControl>}
      <div className={classes.inputContainer}>
        <TextField
          className={classes.input}
          label="Nova tarefa"
          onChange={handleTaskChange}
          value={task}
          variant="outlined"
        />
        <Button variant="contained" color="primary" onClick={handleAddTask}>
          {editIndex >= 0 ? 'Salvar' : 'Adicionar'}
        </Button>
      </div>
      <div className={classes.listContainer}>
        <List>
          {tasks.map((task, index) => (
            <ListItem key={index} className={classes.list}>
              <ListItemText primary={task.name} secondary={task.updatedAt.toLocaleString()} />
              <ListItemSecondaryAction>
                <IconButton onClick={() => handleEditTask(index)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={(e) => {
                  setConfirmModalOpen(true);
                  setDeletingTask(task);
                }}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );
};


export default ToDoList;
