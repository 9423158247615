import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import TagSelect from "../TagSelect";
import WhatsAppSelect from "../WhatsAppSelect";
import PermissionSelect from "../PermissionSelect";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import useWhatsApps from "../../hooks/useWhatsApps";
import CheckboxField from "../FormFields/CheckboxField";
import { Box, Grid, ListItemText, Tab, Tabs, TextareaAutosize, Typography } from "@material-ui/core";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const UserSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  password: Yup.string().min(5, "Too Short!").max(50, "Too Long!"),
  email: Yup.string().email("Invalid email").required("Required"),
});

const UserModal = ({ open, onClose, userId }) => {
  const classes = useStyles();

  const initialState = {
    name: "",
    email: "",
    password: "",
    profile: "user",
    allTicket: "desabled",
    tokenWhats: "",
    userWhats: "",
    leadMessage: "",
    sector: "",
    contactCustomFields: "",
    sendWhatsAppInLeadMessage: false,
    allowViewMessagesWithoutQueue: false,
    allowViewAllConversationMessage: false,
    allowViewAllContacts: false,
    allowGroups: false,
    allowAfterSales: false,
    isAfterSalesManager: false,
    restartServiceWhenFinished: true,
    scheduleSendAt: new Date(),
    scheduleNotifyBeforeText: "",
    scheduleNotifyBefore: 15,
    scheduleNotifyNowText: "",
    daysUntilNextScheduleNotify: null
  };

  const { user: loggedInUser } = useContext(AuthContext);

  const [user, setUser] = useState(initialState);
  const [selectedQueueIds, setSelectedQueueIds] = useState([]);
  const [selectedTagIds, setSelectedTagIds] = useState([]);
  const [selectedPermissionIds, setSelectedPermissionIds] = useState([]);
  const [whatsappId, setWhatsappId] = useState(false);
  const [whatsappIds, setWhatsappIds] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const { loading, whatsApps } = useWhatsApps();
  const [selectedConnection, setSelectedConnection] = useState(null);
  const [dataWhatsapps, setDataWhatsapps] = useState([]);

  useEffect(() => {
    const fetchUser = async () => {
      if (!userId) {
        return;
      }

      try {
        const { data } = await api.get(`/users/${userId}`);
        setUser((prevState) => {
          return {
            ...prevState,
            ...data,
          };
        });
        const userQueueIds = data.queues?.map((queue) => queue.id);
        setSelectedQueueIds(userQueueIds);
        const userTagsIds = data.tags?.map((tag) => tag.id);
        setSelectedTagIds(userTagsIds);
        const selectedPermissionIds = data.permissions?.map(
          (permission) => permission.id
        );
        setSelectedPermissionIds(selectedPermissionIds);
        setWhatsappId(data.whatsappId ? data.whatsappId : "");
        setWhatsappIds(data.whatsapps?.map((item) => item.id) ?? []);
        setDataWhatsapps(whatsApps)
        setSelectedConnection(data.usedConnection)
      } catch (err) {
        toastError(err);
      }
    };

    fetchUser();
  }, [userId, open]);

  const handleClose = () => {
    onClose();
    setCurrentTab(0);
    setUser(initialState);
    setSelectedQueueIds([]);
    setSelectedTagIds([]);
    setSelectedPermissionIds([]);
  };

  const handleSaveUser = async (values) => {
    const userData = {
      ...values,
      whatsappId,
      whatsappIds,
      queueIds: selectedQueueIds,
      tagsIds: selectedTagIds,
      permissionsIds: selectedPermissionIds,
      allTicket: values.allTicket,
      usedConnection: selectedConnection
    };

    try {
      if (userId) {
        await api.put(`/users/${userId}`, userData);
      } else {
        await api.post("/users", userData);
      }
      toast.success(i18n.t("userModal.success"));
    } catch (err) {
      toastError(err);
    }
    handleClose();
  };

  const handleCurrentTab = (_, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {userId
            ? `${i18n.t("userModal.title.edit")}`
            : `${i18n.t("userModal.title.add")}`}
        </DialogTitle>
        <Formik
          initialValues={user}
          enableReinitialize={true}
          validationSchema={UserSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveUser(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <Tabs
                  value={currentTab}
                  onChange={handleCurrentTab}
                  indicatorColor="primary"
                >
                  <Tab label="Dados Gerais" />
                  <Tab label="Parâmetros" />
                  <Tab label="Agendamento" />
                </Tabs>
                {currentTab === 0 && (
                  <Box>
                    <div className={classes.multFieldLine}>
                      <Field
                        as={TextField}
                        label={i18n.t("userModal.form.name")}
                        autoFocus
                        name="name"
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                      />
                      <Field
                        as={TextField}
                        label={i18n.t("userModal.form.password")}
                        type="password"
                        name="password"
                        error={touched.password && Boolean(errors.password)}
                        helperText={touched.password && errors.password}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                      />
                    </div>
                    <div className={classes.multFieldLine}>
                      <Field
                        as={TextField}
                        label={i18n.t("userModal.form.email")}
                        name="email"
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                      />
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        margin="dense"
                      >
                        <Can
                          role={loggedInUser.profile}
                          perform="user-modal:editProfile"
                          yes={() => (
                            <>
                              <InputLabel id="profile-selection-input-label">
                                {i18n.t("userModal.form.profile")}
                              </InputLabel>

                              <Field
                                as={Select}
                                label={i18n.t("userModal.form.profile")}
                                name="profile"
                                labelId="profile-selection-label"
                                id="profile-selection"
                                required
                              >
                                <MenuItem value="admin">Admin</MenuItem>
                                <MenuItem value="user">User</MenuItem>
                              </Field>
                            </>
                          )}
                        />
                      </FormControl>
                    </div>
                    <Can
                      role={loggedInUser.profile}
                      perform="user-modal:editQueues"
                      yes={() => (
                        <QueueSelect
                          selectedQueueIds={selectedQueueIds}
                          onChange={(values) => setSelectedQueueIds(values)}
                        />
                      )}
                    />
                    <Can
                      role={loggedInUser.profile}
                      perform="user-modal:editTags"
                      yes={() => (
                        <TagSelect
                          selectedTagIds={selectedTagIds}
                          onChange={(values) => setSelectedTagIds(values)}
                        />
                      )}
                    />
                    <Can
                      role={loggedInUser.profile}
                      perform="user-modal:editPermissions"
                      yes={() => (
                        <PermissionSelect
                          selectedPermissionIds={selectedPermissionIds}
                          onChange={(values) =>
                            setSelectedPermissionIds(values)
                          }
                        />
                      )}
                    />
                    <Can
                      role={loggedInUser.profile}
                      perform="user-modal:editProfile"
                      yes={() => (
                        <FormControl
                          variant="outlined"
                          margin="dense"
                          className={classes.maxWidth}
                          fullWidth
                        >
                          <InputLabel shrink={!!whatsappId}>
                            {i18n.t("userModal.form.whatsapp")}
                          </InputLabel>
                          <Field
                            as={Select}
                            value={whatsappId}
                            onChange={(e) => setWhatsappId(e.target.value)}
                            label={i18n.t("userModal.form.whatsapp")}
                          >
                            <MenuItem value={""}>&nbsp;</MenuItem>
                            {whatsApps.map((whatsapp) => (
                              <MenuItem key={whatsapp.id} value={whatsapp.id}>
                                {whatsapp.name}
                              </MenuItem>
                            ))}
                          </Field>
                        </FormControl>
                      )}
                    />

                    <Can
                      role={loggedInUser.profile}
                      perform="user-modal:editProfile"
                      yes={() => (
                        <WhatsAppSelect
                          selectedWhatsappIds={whatsappIds}
                          onChange={(values) => {
                            setWhatsappIds(values);
                          }}
                        />
                      )}
                    />

                    <div className={classes.multFieldLine}>
                      <Field
                        as={TextField}
                        label={i18n.t("userModal.form.tokenWhats")}
                        name="tokenWhats"
                        error={touched.tokenWhats && Boolean(errors.tokenWhats)}
                        helperText={touched.tokenWhats && errors.tokenWhats}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                      />
                      <Field
                        as={TextField}
                        label={i18n.t("userModal.form.userWhats")}
                        name="userWhats"
                        error={touched.userWhats && Boolean(errors.userWhats)}
                        helperText={touched.userWhats && errors.userWhats}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                      />
                    </div>
                  </Box>
                )}

                {currentTab === 1 && (
                  <Box>
                    <Box>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <Can
                            role={loggedInUser.profile}
                            perform="user-modal:editProfile"
                            yes={() =>
                              !loading && (
                                <div className={classes.textField}>
                                  <FormControl
                                    variant="outlined"
                                    className={classes.maxWidth}
                                    margin="dense"
                                    fullWidth
                                  >
                                    <>
                                      <InputLabel id="profile-selection-input-label">
                                        {i18n.t("userModal.form.allTicket")}
                                      </InputLabel>

                                      <Field
                                        as={Select}
                                        label={i18n.t(
                                          "allTicket.form.viewTags"
                                        )}
                                        name="allTicket"
                                        labelId="allTicket-selection-label"
                                        id="allTicket-selection"
                                        required
                                      >
                                        <MenuItem value="enabled">
                                          {i18n.t(
                                            "userModal.form.allTicketEnabled"
                                          )}
                                        </MenuItem>
                                        <MenuItem value="desabled">
                                          {i18n.t(
                                            "userModal.form.allTicketDesabled"
                                          )}
                                        </MenuItem>
                                      </Field>
                                    </>
                                  </FormControl>
                                </div>
                              )
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            as={TextField}
                            label={i18n.t("Setor")}
                            name="sector"
                            error={touched.sector && Boolean(errors.sector)}
                            helperText={touched.sector && errors.sector}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Box>

                    <Field
                      as={TextField}
                      label={i18n.t("Mensagem padrão para o lead")}
                      name="leadMessage"
                      error={touched.leadMessage && Boolean(errors.leadMessage)}
                      helperText={touched.leadMessage && errors.leadMessage}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />

                    <Box>
                      <Field
                        as={CheckboxField}
                        label={i18n.t("Enviar WhatsApp do lead?")}
                        name="sendWhatsAppInLeadMessage"
                        variant="outlined"
                        margin="dense"
                      />
                    </Box>

                    <Box>
                      <Field
                        as={CheckboxField}
                        label={i18n.t("Visualiza mensagens sem fila?")}
                        name="allowViewMessagesWithoutQueue"
                        variant="outlined"
                        margin="dense"
                      />
                    </Box>

                    <Box>
                      <Field
                        as={CheckboxField}
                        label={i18n.t("Visualiza todas as mensagens da conversa?")}
                        name="allowViewAllConversationMessage"
                        variant="outlined"
                        margin="dense"
                      />
                    </Box>

                    <Box>
                      <Field
                        as={CheckboxField}
                        label={i18n.t("Visualiza todos os contatos?")}
                        name="allowViewAllContacts"
                        variant="outlined"
                        margin="dense"
                      />
                    </Box>

                    <Box>
                      <Field
                        as={CheckboxField}
                        label={i18n.t("Visualiza grupos?")}
                        name="allowGroups"
                        variant="outlined"
                        margin="dense"
                      />
                    </Box>

                    <Box>
                      <Field
                        as={CheckboxField}
                        label={i18n.t("Visualiza Pós-Venda?")}
                        name="allowAfterSales"
                        variant="outlined"
                        margin="dense"
                      />
                    </Box>

                    <Box>
                      <Field
                        as={CheckboxField}
                        label={i18n.t("Coordenador Pós-Venda?")}
                        name="isAfterSalesManager"
                        variant="outlined"
                        margin="dense"
                      />
                    </Box>
                    <Box>
                      <Field
                        as={CheckboxField}
                        label={i18n.t("Reinicia atendimento ao finalizar?")}
                        name="restartServiceWhenFinished"
                        variant="outlined"
                        margin="dense"
                      />
                    </Box>

                    <Box>
                      <Field
                        style={{ width: "100%" }}
                        as={TextareaAutosize}
                        label={i18n.t("Informações adicionais do contato")}
                        name="contactCustomFields"
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        rows="5"
                      />
                    </Box>
                  </Box>
                )}

                {currentTab === 2 && (
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          rows={9}
                          multiline={true}
                          label={i18n.t("Mensagem de aviso")}
                          name="scheduleNotifyBeforeText"
                          error={
                            touched.scheduleNotifyBeforeText &&
                            Boolean(errors.scheduleNotifyBeforeText)
                          }
                          helperText={
                            touched.scheduleNotifyBeforeText &&
                            errors.scheduleNotifyBeforeText
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          rows={9}
                          multiline={true}
                          label={i18n.t("Mensagem imediata")}
                          name="scheduleNotifyNowText"
                          error={
                            touched.scheduleNotifyNowText &&
                            Boolean(errors.scheduleNotifyNowText)
                          }
                          helperText={
                            touched.scheduleNotifyNowText &&
                            errors.scheduleNotifyNowText
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          label={i18n.t("scheduleModal.form.sendAt")}
                          type="time"
                          name="scheduleSendAt"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={
                            touched.scheduleSendAt &&
                            Boolean(errors.scheduleSendAt)
                          }
                          helperText={
                            touched.scheduleSendAt && errors.scheduleSendAt
                          }
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          label={i18n.t(
                            "Tempo para mensagem de aviso em minutos"
                          )}
                          type="number"
                          name="scheduleNotifyBefore"
                          InputProps={{
                            inputProps: { min: 0 },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={
                            touched.scheduleNotifyBefore &&
                            Boolean(errors.scheduleNotifyBefore)
                          }
                          helperText={
                            touched.scheduleNotifyBefore &&
                            errors.scheduleNotifyBefore
                          }
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          label={i18n.t(
                            "Dia(s) para o próximo agendamento"
                          )}
                          type="number"
                          name="daysUntilNextScheduleNotify"
                          InputProps={{
                            inputProps: { min: 0 },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={
                            touched.daysUntilNextScheduleNotify &&
                            Boolean(errors.daysUntilNextScheduleNotify)
                          }
                          helperText={
                            touched.daysUntilNextScheduleNotify &&
                            errors.daysUntilNextScheduleNotify
                          }
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Select
                          //required
                          fullWidth
                          displayEmpty
                          variant="outlined"
                          value={selectedConnection}
                          onChange={(e) => {
                            setSelectedConnection(e.target.value)
                          }}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                          renderValue={() => {
                            if (!dataWhatsapps.length) {
                              return <span style={{ fontSize: "1rem" }}>Nenhuma conexão configurada</span>;
                            }

                            if (selectedConnection === "") {
                              return <span style={{ fontSize: "1rem" }}>Selecione uma Conexão</span>;
                            }

                            const whatsapp = dataWhatsapps.find(w => w.id === selectedConnection)
                            return <span style={{ fontSize: "1rem" }}>{whatsapp?.name ?? "Selecione uma Conexão"}</span>;
                          }}
                        >
                          {dataWhatsapps?.length > 0 &&
                            dataWhatsapps.map((whatsapp, key) => (
                              <MenuItem dense key={key} value={whatsapp.id}>
                                <ListItemText
                                  primary={
                                    <>
                                      {/* {IconChannel(whatsapp.channel)} */}
                                      <Typography component="span" style={{ fontSize: 14, marginLeft: "10px", display: "inline-flex", alignItems: "center", lineHeight: "2" }}>
                                        {whatsapp.name} &nbsp; <p className={(whatsapp.status) === 'CONNECTED' ? classes.online : classes.offline} >({whatsapp.status})</p>
                                      </Typography>
                                    </>
                                  }
                                />
                              </MenuItem>
                            ))}
                        </Select>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("userModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {userId
                    ? `${i18n.t("userModal.buttons.okEdit")}`
                    : `${i18n.t("userModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default UserModal;
