// DashboardTicketsHappeningsNotContinued
import React, { useState, useEffect } from "react";
import { 
  Badge,
  Box, 
  Button, 
  CircularProgress, 
  Dialog, 
  DialogTitle, 
  IconButton, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableFooter, 
  TableHead, 
  TableRow 
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useTheme } from '@mui/material/styles';
import { format, formatDistanceToNow, differenceInMinutes } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { toast } from 'react-toastify';
import api from "../../services/api";


const DashboardTicketsHappeningsNotContinued = ({
  open,
  onClose,
  data
}) => {
  const theme = useTheme();
  const [count, setCount] = useState(0);
  const [ticketsData, setTicketsData] = useState([]);
  const [currentPage, setCurrentPage] = React.useState(0);
  const rowsPerPage = 9;

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    onClose();
  };

  useEffect(() => {
    setCount(0)
    setTicketsData([])
    handleGetTicketsNotContinued();
  }, [open === true]);

  const handleGetTicketsNotContinued = async () => {
    if(!data.companyId) return;

    const params = {
      companyId: data.companyId,
      finalDate: format(new Date(), 'yyyy-MM-dd')
    }


    try {
      const { data } = await api.request({
        url: `/dashboard/ticketsHappeningsNotContinued`,
        method: 'GET',
        params: params
      });

      setTicketsData(data.data);
      setCount(data.data.length)
    } catch (error) {
        toast.error('Erro ao buscar informações dos tickets');
    }
  }

  const paginatedData = ticketsData.slice(
    currentPage * rowsPerPage,
    currentPage * rowsPerPage + rowsPerPage
  );

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="xl" scroll="paper">
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <DialogTitle>
            <Badge 
              badgeContent={count} 
              color="primary"
              max={999}
              sx={{
                '& .MuiBadge-anchorOriginTopRightRectangle': {
                  right: '-1.5rem !important',
                },
              }}
            >
              <span>Atendimentos não continuados</span>
            </Badge>
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <Paper
          variant="outlined"
          style={{
            marginInline: '1rem',
            marginBottom: '1rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '400px',
            minWidth: '1300px',
            overflow: 'auto',
          }}
        >
          {!ticketsData || ticketsData.length === 0 ? <CircularProgress style={{color:"#d3d3d3"}} /> :
            <>
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Atendimento</TableCell>
                    <TableCell align="center">Atendente</TableCell>
                    <TableCell align="center">Contato</TableCell>
                    <TableCell align="center">Numero</TableCell>
                    <TableCell align="center">Fila</TableCell>
                    <TableCell align="center">Tempo Ocioso</TableCell>
                    <TableCell align="center">Data última conversa</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ticketsData ? paginatedData.map((ticket, index) => (
                    <TableRow key={index}>
                      <TableCell align="center" sx={{ whiteSpace: "nowrap", display: "inline-block" }}>{ticket.ticketId}</TableCell>
                      <TableCell align="center" sx={{ whiteSpace: "nowrap", display: "inline-block" }}>{ticket.attendant}</TableCell>
                      <TableCell align="center" sx={{ whiteSpace: "nowrap", display: "inline-block" }}>{ticket.contact}</TableCell>
                      <TableCell align="center" sx={{ whiteSpace: "nowrap", display: "inline-block" }}>{ticket.contactNumber}</TableCell>
                      <TableCell align="center" sx={{ whiteSpace: "nowrap", display: "inline-block" }}>
                        <Box
                          sx={{
                            display: 'inline-block',
                            padding: '8px 16px',
                            backgroundColor: ticket.queueColor || '#d3d3d385',
                            borderRadius: '4px',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            color: ticket.queueColor ? 'white' : 'black',
                          }}
                        >
                          {ticket.queueName}
                        </Box>
                      </TableCell>
                      <TableCell align="center" sx={{ whiteSpace: "nowrap", display: "inline-block" }}>{`${differenceInMinutes(new Date(), new Date(ticket.updatedAt))} minutos atrás`}</TableCell>
                      <TableCell align="center" sx={{ whiteSpace: "nowrap", display: "inline-block" }}>{format(new Date(ticket.updatedAt), 'dd/MM/yyyy HH:mm:ss')}</TableCell>
                    </TableRow>
                  )) : null }
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <Box display="flex" justifyContent="center" padding="16px">
                        <Button
                          variant="outlined"
                          disabled={currentPage === 0}
                          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 0))}
                        >
                          Anterior
                        </Button>
                        <Box paddingX="16px" display="flex" alignItems="center">
                          Página {currentPage + 1} de {Math.ceil(ticketsData.length / rowsPerPage)}
                        </Box>
                        <Button
                          variant="outlined"
                          disabled={currentPage === Math.ceil(ticketsData.length / rowsPerPage) - 1}
                          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, Math.ceil(ticketsData.length / rowsPerPage) - 1))}
                        >
                          Próxima
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </>
          }
        </Paper>
      </Dialog>
    </>
  )
}

export default DashboardTicketsHappeningsNotContinued;